.container {
  background-color: #DACAE5;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
}

.container_elem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.navbar_line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 25vw;
  align-items: center;
}

.navbar {
  width: 25vw;
  height: 20vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.text a {
  text-decoration: none;
  color: #47414D;
}

.links {
  display: flex;
  color: #47414D;
  text-decoration: none;
}

.links:hover {
  color: #4A3311;
}

.line {
  width: 1px;
  height: 20vh;
  background-color: #47414D;
}


.picture {
  position: relative;
  width: 15vw;
}

.text_logo {
  position: relative;
  height: 25vh;
  text-align: right;
  align-items: flex-end;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.text_logo p {
  text-align: right;
}

.logo {
  width: 3vw;
}

@media (max-width: 600px) {
  .links {
    font-size: 1.5vh;
  }

  .line {
    height: 10vh;
  }

  .text {
    font-size: 1.5vh;
  }

  .navbar_line {
    width: 30vw;
  }

  .container {
    height: 35vh;
  }

  .navbar {
    height: 15vh;
  }

  .text_logo {
    height: 15vh;
  }
}