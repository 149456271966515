@font-face {
  font-family: "CormorantGaramond-Bold";
  src: local("CormorantGaramond-Bold"),
    url("./data/fonts/CormorantGaramond-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-BoldItalic";
  src: local("CormorantGaramond-BoldItalic"),
    url("./data/fonts/CormorantGaramond-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-MediumItalic";
  src: local("CormorantGaramond-MediumItalic"),
    url("./data/fonts/CormorantGaramond-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-Medium";
  src: local("CormorantGaramond-Medium"),
    url("./data/fonts/CormorantGaramond-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-Light";
  src: local("CormorantGaramond-Light"),
    url("./data/fonts/CormorantGaramond-Light.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-LightItalic";
  src: local("CormorantGaramond-LightItalic"),
    url("./data/fonts/CormorantGaramond-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "CormorantGaramond-Italic";
  src: local("CormorantGaramond-Italic"),
    url("./data/fonts/CormorantGaramond-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "gilroy-bold";
  src: local("gilroy-bold"),
    url("./data/fonts/gilroy-bold.ttf") format("truetype");
}

@font-face {
  font-family: "gilroy-semibold";
  src: local("gilroy-semibold"),
    url("./data/fonts/gilroy-semibold.ttf") format("truetype");
}

@font-face {
  font-family: "gilroy-medium";
  src: local("gilroy-medium"),
    url("./data/fonts/gilroy-medium.ttf") format("truetype");
}

@font-face {
  font-family: "gilroy-regular";
  src: local("gilroy-regular"),
    url("./data/fonts/gilroy-regular.ttf") format("truetype");
}

body {
  font-family: 'gilroy-regular', sans-serif;
  font-size: 3vh;
  line-height: 130%;
  margin: 0;
  padding: 0;
  font-weight: 0;
  color: #47414D;
  background: url('./data/bg.png') no-repeat top center;
  background-size: cover;
}

a {
  color: #47414D;
}

nav {
  font-family: 'gilroy-regular', sans-serif;
  font-size: 3vh;
  color: #47414D;
  line-height: normal;
  text-decoration: none;
  display: flex;
}

h1 {
  font-family: 'gilroy-semibold', sans-serif;
  font-size: 8vh;
  margin: 0;
  padding: 0;
  color: #47414D;
  line-height: normal;
}

h2 {
  font-family: 'CormorantGaramond-LightItalic', sans-serif;
  font-size: 4.5vh;
  margin: 0;
  padding: 0;
  color: #47414D;
  line-height: normal;
}

h3 {
  font-family: 'CormorantGaramond-Light', sans-serif;
  font-size: 7vh;
  margin: 0;
  padding: 0;
  color: #47414D;
}

h4 {
  font-family: 'CormorantGaramond-MediumItalic', sans-serif;
  font-size: 3vh;
  margin: 0;
  padding: 0;
  color: #47414D;
}

h5 {
  font-family: 'CormorantGaramond-Light', sans-serif;
  font-size: 3.5vh;
  margin: 0;
  padding: 0;
  color: #47414D;
  line-height: 130%;
}

button {
  color: #fff;
  font-family: 'gilroy-regular', sans-serif;
  font-size: 2.5vh;
  cursor: pointer;
  border-radius: 10vh;
  padding: 2vh 2vh;
  background: #9827DD;
  transition: 0.15s;
  border: 1px solid #9827DD;
  width: fit-content;
  height: fit-content;
  padding-left: 2vw;
  padding-right: 2vw;
}

button:hover {
  color: #000000;
  transform: translate(-0.25rem, -0.25rem);
  background: #fff;
  box-shadow: 0.25rem 0.25rem #000;
  border: 1px solid #000;
}

button:active {
  transform: translate(0);
  box-shadow: none;
}

@media (max-width: 800px) {
  body {
    font-size: 2vh;
  }
  h1 {
    font-size: 5vh;
    /* line-height: 110%; */
  }
  
  h2 {
    font-size: 3.5vh;
    /* line-height: 110%; */
  }
  
  h3 {
    font-size: 4vh;
    /* line-height: 110%; */
  }

  h5 {
    font-size: 2.5vh;
  }

  button {
    font-size: 1.8vh;
    padding-left: 4vw;
    padding-right: 4vw;
  }
}