.container {
    /* background-color: #FFFFFF; */
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    color: #47414D;
}

@media (max-width: 600px) {
    .container {
        padding-top: 10vh;
    }
}