.container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    margin-top: 5vh;
}

.container_main {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 15vh;
}

.container_main img {
    width: 20vw;
    display: flex;
}

.main_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    text-align: left;
    align-items: end;
    width: 35vw;
}

.main_text h2 {
    margin-top: 2vh;
}

.main_text h1 {
    border: 1px solid #47414D;
    border-radius: 10vh;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 3vw;
    padding-right: 3vw;
    display: inline-block;
    position: relative;
  }

.main_text h1::after {
    content: "";
    position: absolute;
    right: 2vw;
    top: 5%;
    transform: translateY(-50%);
    width: 2vw;
    height: 2vw;
    background-image: url('../../data/logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

.container_problems {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: auto;
    margin-bottom: 5vh;
}

.problem {
  width: 100%;
  border-bottom: 1px solid #47414D; /* Цвет текста */
  padding: 5vh 0;
}

.problem:last-child {
  border-bottom: none;
}

.problemTitle {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1vh 0;
  transition: color 0.3s ease-in-out; /* Плавная анимация заголовка */
}

.arrow {
  margin-right: 2vw; /* Стрелочка слева */
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.arrow.open {
  transform: rotate(90deg); /* Поворот стрелочки */
}

.answers {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding-left: 2vw; /* Оставляем отступ слева для контента */
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.5s ease-in-out; /* Плавное скрытие и появление, включая отступы */
}

.answers.visible {
  max-height: 1000vh;
  opacity: 1;
  padding-left: 2vw; /* Отступ для видимого контента */
}

.answer {
  margin-top: 2vh;
  border-left: 3px solid #9827DD; /* Линия слева вместо фона */
  border-radius: 2.5vh;
  padding: 2.5vh 2vw;
}

.answer p {
  margin: 0;
  line-height: 140%;
}

.author {
  font-size: 2.5vh;
  color: #666;
  margin-top: 1vh;
}

.answer a {
  display: block;
  margin-top: 1vh;
  font-size: 2.5vh;
  color: #9827DD;
  text-decoration: none;
}

.answer a:hover {
  text-decoration: underline;
}

.container_text {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 5vh;
    border: 1px solid #47414D;
    border-radius: 5vh;
}

.text_text {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 4vw;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
}

  @media (max-width: 600px) {
    .container_main {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10vh;
    }

    .container_main img {
        margin-left: 0;
        margin-top: 2vh;
        width: 35vw;
    }

    .main_text {
        width: 75vw;
        align-items: end;
    }

    .main_text h1 {
        width: 65vw;
        padding-right: 6vw;
        text-align: end;
        font-size: 4.3vh;
    }

    .main_text h1::after {
        right: 6vw;
        width: 4vw;
        height: 4vw;
      }

    .author {
        font-size: 2vh;
    }

    .arrow {
        margin-right: 5vw;
      }

    .answer a {
        font-size: 2vh;
    }

    .container_text {
        margin-bottom: 2vh;
    }

    .answer {
        border-left: 2px solid #9827DD;
        padding: 2vh 4vw;
      }
  }