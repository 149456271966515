.container {
  width: 80%;
  display: flex;
  align-items: center;
  height: 50vh;
  margin-left: 10%;
  margin-right: 10%;
  position: relative;
  margin-top: 5vh;
  justify-content: center;
}

.text {
  display: flex;
  color: gray;
  flex-direction: column;
  justify-content: center;
  font-size: 3vh;
  font-weight: 300;
  font-family: 'Roboto Light';
}