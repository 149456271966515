.container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    margin-top: 5vh;
}

.container_main {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 15vh;
}

.container_main img {
    width: 30vw;
    display: flex;
}

.main_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    text-align: left;
    width: 39vw;
}

.main_text h2 {
    margin-top: 2vh;
}

.main_text h1 {
    border: 1px solid #47414D;
    border-radius: 10vh;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 4vw;
    display: inline-block;
    width: 35vw;
    position: relative;
  }

.main_text h1::after {
    content: "";
    position: absolute;
    right: 3vw;
    top: 5%;
    transform: translateY(-50%);
    width: 3vw;
    height: 3vw;
    background-image: url('../../data/logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

.highlight {
    color: #9827DD;
}

.container_about {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 10vh;
    background-color: #fff;
    border: 1px solid #47414D;
    border-radius: 5vh;
}

.container_about ::after {
    content: "";
    position: absolute;
    right: 2vw;
    top: -2%;
    transform: translateY(-50%);
    width: 2vw;
    height: 2vw;
    background-image: url('../../data/logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

.about_text {
    padding-top: 3vh;
    padding-bottom: 3vh;
    padding-left: 4vw;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.container_description_1 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 10vh;
}

.container_description_2 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 15vh;
}

.description_text {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: auto;
    text-align: left;
    width: 55vw;
}

.container_description_1 img {
    height: 15vw;
}

.container_description_2 img {
    height: 15vw;
}
    
.description_text p {
    margin-block-start: 0;
    margin-block-end: 0;
}
    
.description_text h2 {
    border: 1px solid #47414D;
    border-radius: 10vh;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 2vw;
    display: inline-block;
    width: 25vw;
    position: relative;
    margin-bottom: 2vh;
}

.description_text p {
    margin-bottom: 2vh;
}

.description_text h2::after {
    content: "";
    position: absolute;
    right: 2vw;
    top: 8%;
    transform: translateY(-50%);
    width: 2vw;
    height: 2vw;
    background-image: url('../../data/logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

.container_psychologist {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10vh;
}

.container_psychologist h3 {
    margin-bottom: 2vh;
}

.ps_block_1 {
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    margin-top: 5vh;
}

.ps_block_2 {
    display: flex;
    flex-direction: row-reverse;
    height: auto;
    justify-content: space-between;
    margin-top: 5vh;
}

.ps_block_a {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ps_block_a_1 {
    border-radius: 10vh;
    background-color: #9827DD;
    color: #fff;
    padding: 1vw;
    text-align: center;
}

.ps_block_a_2 {
    border-radius: 5vh;
    border: 1px solid #47414D;
    color: #47414D;
    padding: 2vh 2vw;
    text-align: center;
}

.ps_block_a_3 {
    border-radius: 5vh;
    border: 1px solid #47414D;
    color: #47414D;
    padding: 2vh 2vw;
    text-align: center;
}

.ps_block_b_1 {
    width: 45%;
    border-radius: 5vh 5vh 0vh 5vh;
    border: 1px solid #47414D;
    padding: 2vh 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ps_block_b_2 {
    width: 45%;
    border-radius: 5vh 5vh 5vh 0vh;
    border: 1px solid #47414D;
    padding: 2vh 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ps_block_c_1 {
    width: 15%;
    height: auto;
    object-fit: cover;
    border-radius: 5vh;
    border: 1px solid #47414D;
}

.ps_block_c_2 {
    width: 15%;
    height: auto;
    object-fit: cover;
    border-radius: 5vh;
    border: 1px solid #47414D;
}

.container_people {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vh;
}

.container_people h3 {
    margin-bottom: 7vh;
}

.people_block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.people_block_1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

.people_block_2 {
    margin-top: 5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
}

.person {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.person_1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.person_2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.person img {
    width: 12vw;
    height: 12vw;
    margin-bottom: 2vh;
}

.person_1 img {
    width: 12vw;
    height: 12vw;
    margin-bottom: 2vh;
}

.person_2 img {
    width: 12vw;
    height: 12vw;
    margin-bottom: 2vh;
}

.person_text {
    text-align: center;
}

.person_subject {
    font-size: 2vh;
    color: #666;
    margin-block-start: 0;
    margin-block-end: 0;
}

.person_text a {
  display: block;
  /* margin-top: 1vh; */
  font-size: 2vh;
  color: #9827DD;
  text-decoration: none;
}

.person_text a:hover {
  text-decoration: underline;
}

.container_gratitude {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 5vh;
    border: 1px solid #47414D;
    border-radius: 5vh;
}

.gratitude_text {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 4vw;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
}


@media (max-width: 600px) {
    .container_main {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10vh;
    }

    .container_main img {
        margin-left: 0;
        margin-top: 2vh;
        width: 35vw;
    }

    .main_text {
        width: 75vw;
        align-items: end;
    }

    .main_text h1 {
        width: 60vw;
        padding-right: 6vw;
        text-align: end;
    }

    .main_text h1::after {
        right: 6vw;
        width: 4vw;
        height: 4vw;
      }

    .container_about {
        margin-bottom: 5vh;
    }

    .container_about ::after {
        right: 5vw;
        top: -1%;
        width: 4vw;
        height: 4vw;
      }

    .about_text {
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .container_description_1 {
        flex-direction: column-reverse;
        margin-bottom: 5vh;
        align-items: start;
    }

    .container_description_2 {
        flex-direction: column-reverse;
        margin-bottom: 5vh;
        align-items: start;
    }

    .container_description_1 img {
        height: auto;
        width: 30%;
        margin-bottom: 3vh;
    }
    
    .container_description_2 img {
        height: auto;
        width: 30%;
        margin-bottom: 3vh;
    }

    .description_text {
        width: 100%;
    }

    .description_text h2 {
        width: auto;
        padding-top: 1vh;
        padding-bottom: 1vh;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .description_text h2::after {
        content: "";
        position: absolute;
        right: 2vw;
        top: 8%;
        width: 3vw;
        height: 3vw;
      }

    .container_psychologist {
        margin-bottom: 8vh;
    }

    .container_psychologist h3 {
        margin-bottom: 0;
    }
    
    .ps_block_1 {
        flex-direction: column;
    }

    .ps_block_2 {
        flex-direction: column;
    }

    .ps_block_a {
        width: 100%;
    }

    .ps_block_a_1 {
        margin-bottom: 1.5vh;
        padding: 2vh 4vw;
    }

    .ps_block_a_2 {
        margin-bottom: 1.5vh;
        padding: 1vh 4vw;
    }

    .ps_block_a_3 {
        margin-bottom: 1.5vh;
        border-radius: 3vh;
        padding: 1vh 4vw;
    }

    .ps_block_b_1 {
        width: 100%;
        margin-bottom: 1.5vh;
        border-radius: 3vh 3vh 0 3vh;
        padding: 0;
    }

    .ps_block_b_2 {
        width: 100%;
        margin-bottom: 1.5vh;
        border-radius: 3vh 3vh 3vh 0;
        padding: 0;
    }

    .text_b {
        padding: 1vh 4vw;
    }

    .ps_block_c_1 {
        width: 30%;
        align-self: self-end;
        border-radius: 3vh;
    }

    .ps_block_c_2 {
        width: 30%;
        border-radius: 3vh;
    }

    .container_people {
        margin-bottom: o;
        margin-top: 3vh;
    }

    .container_people h3 {
        margin-bottom: 5vh;
    }

    .people_block {
        flex-direction: column;
    }

    .people_block_1 {
        flex-direction: column;
    }

    .people_block_2 {
        flex-direction: column;
        margin-top: 0;
    }

    .person {
        width: 100%;
        margin-bottom: 3vh;
    }

    .person {
        width: 100%;
        margin-bottom: 3vh;
    }

    .person_1 {
        width: 100%;
        margin-bottom: 3vh;
    }

    .person_2 {
        width: 100%;
        margin-bottom: 3vh;
    }

    .person img {
        height: auto;
        width: 50%;
    }

    .person_1 img {
        height: auto;
        width: 50%;
    }

    .person_2 img {
        height: auto;
        width: 50%;
    }

    .person_text {
        text-align: center;
    }

    .person_text p {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-top: 1vh;
        margin-bottom: 1vh;
    }

    .person_text a {
        font-size: 2vh;
      }

    .container_gratitude {
        margin: 3vh 0;
    }
}